import { createRoot } from "react-dom/client";
import { StrictMode, lazy, Suspense } from "react";
import { kcContext as kcLoginThemeContext } from "./keycloak-theme/login/kcContext";
import { kcContext as kcAccountThemeContext } from "./keycloak-theme/account/kcContext";
import "./style.css";
import "animate.css";
import rocket from "./robolaunch-rocket.png";
import logo from "./Images/logo.png";
import bg from "./Images/bg1.svg";
import bg1 from "./Images/bg.svg";

const KcLoginThemeApp = lazy(() => import("./keycloak-theme/login/KcApp"));
const KcAccountThemeApp = lazy(() => import("./keycloak-theme/account/KcApp"));
const App = lazy(() => import("./App"));

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Suspense>
      <div className="bg-container w-full h-screen flex flex-col items-center text-center min-h-screen overflow-hidden bg-[#040b15] relative">
        <style>
          {`
            .bg-container {
              background-image: url(${bg}), url(${bg1});
              background-position: right -30% top 0, left 0 bottom 50%;
              background-repeat: no-repeat, no-repeat;
              background-size: 50%, 50%;
              
            }

            @media (min-width: 768px) {
              .bg-container {
                background-image: url(${bg}), url(${bg1});
                background-position: right -30% top 0, left 0 bottom 50p%;
                background-repeat: no-repeat, no-repeat;
                background-size: 50%, 50%;
              }
            }
          `}
        </style>
        <div className="w-full flex items-center justify-center overflow-y-scroll">
          {(() => {
            if (kcLoginThemeContext !== undefined) {
              return <KcLoginThemeApp kcContext={kcLoginThemeContext} />;
            }

            if (kcAccountThemeContext !== undefined) {
              return <KcAccountThemeApp kcContext={kcAccountThemeContext} />;
            }

            return <App />;
          })()}
        </div>
      </div>
    </Suspense>
  </StrictMode>
);
